/* eslint-disable no-unused-vars, no-shadow*/
// ! defines all possible routes for each backend call
export const apiRoutes = {
  admin: "/lumos-admin-backend",
  login: "/login",
  logout: "/logout",
  session: "/session", // retrieve the current session and check if user is still logged in
  on_site_authentication: "on-site-authentication",
  user: "/me", // retrieve user information
  users: "/users",
  book_for_users: "/book",
  events: "/events",
  events_filter_values: "/events/filter-values",
  registration: "/registration",
  activate_account: "/activate-account",
  deactivate_account: "/deactivate-account",
  resend_activation_code: "/resend-activation-code",
  forgot_password: "/forgot-password",
  reset_password: "/reset-password",
  change_password: "/change-password",
  participants: "/participants",
  participants_csv: "/participants/csv",
  tasks: "/tasks",
  tasks_filter_users: "/tasks/filter-users",
  speakers: "/speakers",
  documents: "/documents",
  documents_default: "/documents/default",
  document_by_id: "/documents/get-document-by-id",
  booking: "/bookings",
  booking_all: "/bookings/all",
  lectures: "/lectures",
  cart_items: "/cart-items",
  cart: "/cart",
  // TODO: Decide whether old invoice service is still needed
  invoice: "/invoice",
  organization_invoice: "/organization-invoices",
  invoice_correction: "/invoice-correction",
  contact: "/contact",
  favorites: "/favorites",
  favorites_count: "/favorites/count",
  templates_event: "/event-templates",
  templates_task: "/task-templates",
  checklist: "/checklist",
  billing_addresses: "/billing-addresses",
  link_account: "/link-account",
  link_account_validate: "/link-account/validate-account-link",
  link_account_confirmation: "/link-account/confirmation",
  participation_types: "/participation-types",
  statistics: "/statistics",
  elearning_root: "/elearning",
  blended_learnings: "/blended-learnings",
  elearnings: "/e-learnings",
  pinboard: "/pinboard",
  pinboard_comments: "/pinboard-comments",
  pinboard_activated: "/is-pinboard-activated",
  dashboard: "/dashboard",
  dashboard_updates: "/dashboard-updates",
  moodle_login: "/moodle-login",
  moodle_data: "/moodle-data",
  //TODO: saving captures
  authentication_captures: "/auth-captures",
  group_membership_document: "/group-membership-documents",
  group_membership_document_types: "/group-membership-document-types",
  profile: "/profile",
  invoices: "/invoices",
  invoices_count: "/invoices_count",
  orders: "/orders",
  discounts: "/discounts",
  paypal: "/paypal",
  certificate_types: "/certificate-types",
  organization: "/organization",
  organization_types: "/organization-type",
  organization_membership: "/membership",
  activate_organization_membership: "/membership-invite",
  invite_user_to_organization: "/invite-user-to-organization",
  accept_membership: "/accept",
  account_invitation: "/account-invitation",
  download_template: "/download-template",
  maintenance: "/maintenance",
  minimum_age: "/minimum-age",
  frontend_logger: "/frontend-logger",
  payment_methods: "/payment-methods",
  build_version: "/build-version",
};

export enum ApiVersion {
  Default = "/api",
  Version_0_1 = "/api/v0.1",
}

export default apiRoutes;

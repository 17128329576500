import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { BuildVersionData } from "../models/buildVersionData";

export class BuildVersionService extends BaseCrudService {
  async getBuildVersion() {
    const response = await this.create<BuildVersionData>(apiRoutes.build_version);
    return response;
  }
}
